<template>
  <div>
    <a-card :tab-list="tabList" :active-tab-key="currentTab" @tabChange="tabChange">
      <div v-if="currentTab == 'deliveryTaskPanel'">
        <delivery-task-panel></delivery-task-panel>
      </div>
      <div v-else-if="currentTab == 'deliveryRecordPanel'">
        <delivery-record-panel></delivery-record-panel>
      </div>
    </a-card>
  </div>
</template>

<script>
  export default {
    components: {
      DeliveryTaskPanel: () => import('./DeliveryTaskPanel/index'),
      DeliveryRecordPanel: () => import('./DeliveryRecordPanel/index'),
    },
    data() {
      return {
        tabList: [
          {
            key: 'deliveryTaskPanel',
            tab: '复核任务',
          },
          {
            key: 'deliveryRecordPanel',
            tab: '复核记录',
          },
        ],
        currentTab: 'deliveryTaskPanel',
      }
    },
    methods: {
      initData() {
        let currentTab = this.$route.query.currentTab;
        if (currentTab) {
          this.currentTab = currentTab;
        } else {
          this.currentTab = this.tabList[0].key;
          this.$router.push({ query: { currentTab: this.currentTab } });
        }
      },
      tabChange(key) {
        this.currentTab = key;
        this.$router.push({ query: { currentTab: this.currentTab } });
      },
    },
    mounted() {
      this.initData();
    },
  }
</script>